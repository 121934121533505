//引入axios
import axios from 'axios'
import { Toast } from 'vant'
import { BASE_URL } from '@/utils/config'
import router from '@/router'

// 创建axios实例
let baseURL = BASE_URL
let defaultParams = {
  token: localStorage.getItem('token') || '',
}
function checkToken() {
  if (localStorage.getItem('token')) {
    defaultParams['token'] = localStorage.getItem('token')
    // } else {
    // router.replace('/login')
  }
}
let service = axios.create({
  baseURL: baseURL,
  timeout: 10000,
})

//响应拦截器即异常处理
service.interceptors.response.use(
  (res) => {
    if (res && res.data) {
      const message = res.data.message || '未知错误'
      const isHtml = res.headers['content-type']?.includes('text/html');
      switch (res.data.code) {
        case 200: // 成功
          break
        case 105:
          if (router.app.$route.name != 'login') {
            router.replace({
              path: '/login',
              query: {
                next_url: document.location.href,
              },
            })
            localStorage.clear()
          }
          break
        default:
          if (!isHtml) {
            Toast(message)
          }
          console.log(message, res)
          break
      }
    }
    return res
  },
  (err) => {
    if (err && err.response) {
      const { message } = err.response.data
      err.message = (message || '未知错误') + (err.response.status || '')
      Toast(err.message)
    } else {
      Toast('请求错误')
    }
    return Promise.resolve(err && err.response)
  },
)
;(service.get = function (url, param) {
  checkToken()
  return new Promise((resolve, reject) => {
    service({
      method: 'get',
      url,
      params: { ...param },
      headers: {
        token: defaultParams['token'],
      },
    })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}),
  (service.getBlob = function (url, param) {
    checkToken()
    return new Promise((resolve, reject) => {
      return service({
        url,
        method: 'GET',
        params: { ...param },
        headers: {
          token: defaultParams['token'],
        },
        responseType: 'blob',
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }),
  //post请求
  (service.post = function (url, param) {
    checkToken()
    return new Promise((resolve, reject) => {
      service({
        method: 'post',
        url,
        data: { ...param },
        headers: {
          token: defaultParams['token'],
        },
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          Toast.fail(err.message)
          reject(err)
        })
    })
  }),
  (service.postRes = function (url, param) {
    checkToken()
    return new Promise((resolve, reject) => {
      service({
        method: 'post',
        url,
        data: { ...param },
        headers: {
          token: defaultParams['token'],
        },
      })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  })

export default service
